"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SUGGESTED_EVALUATE = exports.DEFAULT_ESCAPE = exports.DEFAULT_INTERPOLATE = undefined;

var _lodash = require("lodash.template");

var _lodash2 = _interopRequireDefault(_lodash);

var _merge = require("./merge");

var _merge2 = _interopRequireDefault(_merge);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Default lodash templates regexs
// https://regex101.com/r/ce27tA/5
const DEFAULT_INTERPOLATE = exports.DEFAULT_INTERPOLATE = /{{{([^{}#][\s\S]+?)}}}/g; // https://regex101.com/r/8sRC8b/8

const DEFAULT_ESCAPE = exports.DEFAULT_ESCAPE = /{{([^{}#][\s\S]+?)}}/g; // https://regex101.com/r/ndDqxg/4

const SUGGESTED_EVALUATE = exports.SUGGESTED_EVALUATE = /{{#([^{}].*?)}}/g;

class MessageBox {
  constructor({
    escape,
    evaluate,
    initialLanguage,
    interpolate,
    messages,
    tracker
  } = {}) {
    this.language = initialLanguage || MessageBox.language || 'en';
    this.messageList = messages || {};

    if (tracker) {
      this.tracker = tracker;
      this.trackerDep = new tracker.Dependency();
    } // Template options


    this.interpolate = interpolate || MessageBox.interpolate || DEFAULT_INTERPOLATE;
    this.evaluate = evaluate || MessageBox.evaluate;
    this.escape = escape || MessageBox.escape || DEFAULT_ESCAPE;
  }

  clone() {
    const copy = new MessageBox({
      escape: this.escape,
      evaluate: this.evaluate,
      initialLanguage: this.language,
      interpolate: this.interpolate,
      tracker: this.tracker
    });
    copy.messages(this.messageList);
    return copy;
  }

  getMessages(language) {
    if (!language) {
      language = this.language;
      if (this.trackerDep) this.trackerDep.depend();
    }

    const globalMessages = MessageBox.messages[language];
    let messages = this.messageList[language];

    if (messages) {
      if (globalMessages) messages = (0, _merge2.default)({}, globalMessages, messages);
    } else {
      messages = globalMessages;
    }

    if (!messages) throw new Error(`No messages found for language "${language}"`);
    return {
      messages,
      language
    };
  }

  message(errorInfo, {
    context,
    language
  } = {}) {
    // Error objects can optionally include a preformatted message,
    // in which case we use that.
    if (errorInfo.message) return errorInfo.message;
    const fieldName = errorInfo.name;
    const genericName = MessageBox.makeNameGeneric(fieldName);
    const {
      messages
    } = this.getMessages(language);
    let message = messages[errorInfo.type];

    const fullContext = _objectSpread({
      genericName
    }, context, {}, errorInfo);

    if (message && typeof message === 'object') message = message[genericName] || message._default; // eslint-disable-line no-underscore-dangle

    if (typeof message === 'string') {
      message = (0, _lodash2.default)(message, {
        interpolate: this.interpolate,
        evaluate: this.evaluate,
        escape: this.escape
      });
    }

    if (typeof message !== 'function') return `${fieldName} is invalid`;
    return message(fullContext);
  }

  messages(messages) {
    (0, _merge2.default)(this.messageList, messages);
  }

  setLanguage(language) {
    this.language = language;
    if (this.trackerDep) this.trackerDep.changed();
  }

  static makeNameGeneric(name) {
    if (typeof name !== 'string') return null;
    return name.replace(/\.[0-9]+(?=\.|$)/g, '.$');
  }

  static defaults({
    escape,
    evaluate,
    initialLanguage,
    interpolate,
    messages
  } = {}) {
    if (typeof initialLanguage === 'string') MessageBox.language = initialLanguage;
    if (interpolate instanceof RegExp) MessageBox.interpolate = interpolate;
    if (evaluate instanceof RegExp) MessageBox.evaluate = evaluate;
    if (escape instanceof RegExp) MessageBox.escape = escape;

    if (messages) {
      if (!MessageBox.messages) MessageBox.messages = {};
      (0, _merge2.default)(MessageBox.messages, messages);
    }
  }

}

_defineProperty(MessageBox, "messages", {});

exports.default = MessageBox;