"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _SimpleSchema = require("./SimpleSchema");

require("./clean");

_SimpleSchema.SimpleSchema.ValidationContext = _SimpleSchema.ValidationContext;
var _default = _SimpleSchema.SimpleSchema;
exports.default = _default;
module.exports = exports.default;
module.exports.default = exports.default;